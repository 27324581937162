<template>
  <div>
    <textarea type="text" v-model="data.description" />
    <columnWidth />
  </div>
</template>
<script>
export default {
  components: {
    columnWidth() {
      return import("./width.vue");
    }
  },
  props: {},
  data() {
    return {
      data: JSON.parse(
        JSON.stringify(
          this.$store.state.business.unsaved.form[this.$store.state.form.edit]
        )
      )
    };
  },
  watch: {
    "data.description"() {
      this.setSave();
    }
  },
  computed: {},
  mounted() {},
  methods: {
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.form[this.$store.state.form.edit] = this.data;
      this.$store.commit("business/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
textarea
  width: 100%
  padding: 10px
  box-sizing: border-box
  border: 1px solid $success
  height: 200px
  &:focus
    outline: none
</style>
